<table
  mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z1"
  multiTemplateDataRows
>
  <ng-container matColumnDef="payeeId">
    <th mat-header-cell *matHeaderCellDef>Ügyazonosító</th>
    <td mat-cell *matCellDef="let element">
      {{ element.payee_case_reference_id }}
    </td>
  </ng-container>

  <ng-container matColumnDef="startedAt">
    <th mat-header-cell *matHeaderCellDef>INDÍTVA</th>
    <td mat-cell *matCellDef="let element">
      <div class="case-started-at-box">
        {{ element.started_at | date : "yyyy.MM.dd. HH:mm:ss" }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="debtors">
    <th mat-header-cell *matHeaderCellDef>FELEK</th>
    <td mat-cell *matCellDef="let element">
      <div class="client">{{ element.parties.client_name }} vs</div>
      <div>
        <strong>{{ element.parties.debtor_name }}</strong>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>STÁTUSZ</th>
    <td mat-cell *matCellDef="let element">
      <div class="status-box">
        <div
          class="state"
          [style.color]="element.state.state_label_text_color"
          [style.background-color]="element.state.state_label_background_color"
        >
          {{ element.state.state_label }}
        </div>
        <div class="stage">
          <!-- <span class="prefix">Szakasz:&nbsp;</span> -->
          <span class="label">{{ element.state.stage_label }}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="claimsSumAmount">
    <th mat-header-cell *matHeaderCellDef>KÖVETELÉS</th>
    <td mat-cell *matCellDef="let element">
      <div class="claims-sum-amount-box">
        <span class="current">
          {{ element.claim.remaining_amount | number : "1.0" : "hu" }}
          {{ element.claim.currency }}
        </span>
        <span class="original">
          {{ element.claim.original_amount | number : "1.0" : "hu" }}
          {{ element.claim.currency }}
        </span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="lastEvent">
    <th mat-header-cell *matHeaderCellDef>Legutóbbi esemény</th>
    <td mat-cell *matCellDef="let element">
      <span class="last-transition">
        {{ element.last_transition.label }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="deadlines">
    <th mat-header-cell *matHeaderCellDef>Határidő</th>
    <td mat-cell *matCellDef="let element">
      <div class="deadlines-box flex column hcenter">
        <div class="hard-deadline" *ngIf="element.deadlines.hard">
          {{ element.deadlines.hard | date : "yyyy.MM.dd. HH:mm:ss" }}
        </div>
        <div class="soft-deadline" *ngIf="element.deadlines.soft">
          {{ element.deadlines.soft | date : "yyyy.MM.dd. HH:mm:ss" }}
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button
        *ngIf="element.actions.length > 0"
        payee-icon-button
        [matMenuTriggerFor]="actionsMenu"
        (menuOpened)="onActionsMenuOpen()"
        (menuClosed)="onActionsMenuClosed()"
        class="actions-menu-button"
        color="white"
      >
        <payee-icon [name]="environment.MORE_ICON"></payee-icon>
      </button>

      <mat-menu #actionsMenu>
        <button
          mat-menu-item
          *ngFor="let event of element.actions"
          (click)="runTransitionWithDialog(element, event)"
        >
          {{ event.label }}
        </button>
        <button mat-menu-item *ngIf="element.actions.length === 0">
          Nincs elérhető esemény
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <ng-container matColumnDef="detailsToggle">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <payee-icon name="arrow-down-grey-no-shaft"></payee-icon>
    </td>
  </ng-container>

  <!-- Expanded Content Column -->
  <ng-container matColumnDef="expandedDetail">
    <td
      mat-cell
      *matCellDef="let element"
      [attr.colspan]="displayedColumns.length"
    >
      <div
        class="element-detail alt-theme flex column"
        [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'"
      >
        <mat-tab-group dynamicHeight>
          <mat-tab *ngIf="element === expandedElement" label="Események">
            <app-case-events [caseId]="element.id"></app-case-events>
          </mat-tab>
          <mat-tab *ngIf="element === expandedElement" label="Részletek">
            <app-case-details
              [caseId]="element.id"
              (filterCase)="filterHistoricCase($event)"
            ></app-case-details>
          </mat-tab>
          <!-- <mat-tab
            *ngIf="element === expandedElement"
            label="Fájlok"
          >
            <div class="files-box flex column">
              <div class="flex vcenter files-box-buttons">
                <button
                  payee-button
                  width="shorter"
                  (click)="uploadFiles(element)"
                >
                  Fájl feltöltése
                </button>
              </div>
              <div
                class="attorney-files-box flex column"
                *ngIf="expandedElementDetails?.attorneyFiles"
              >
                <div
                  *ngFor="let attorneyFile of expandedElementDetails.attorneyFiles"
                  class="flex vcenter attorney-file"
                >
                  <span>{{attorneyFile.name}}</span>
                  <button
                    payee-button
                    width="shorter"
                    (click)="openAttorneyFile(attorneyFile)"
                  >Letöltés</button>
                </div>
              </div>
            </div>
          </mat-tab> -->
        </mat-tab-group>
      </div>
    </td>
  </ng-container>

  <!-- <tr mat-header-row *matHeaderRowDef="filterHeader"></tr> -->
  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"
    class="main-header"
  ></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: displayedColumns"
    class="element-row mat-elevation-z2"
    [class.expanded-row]="expandedElement === element"
    (click)="updateExpandedElement(element)"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: ['expandedDetail']"
    class="detail-row"
  ></tr>
</table>

<mat-paginator
  [pageSize]="pageSize"
  [pageIndex]="pageIndex"
  [pageSizeOptions]="[5, 10, 25, 100]"
  [length]="length"
  (page)="pageChange($event)"
  disableUnderline
  class="mat-elevation-z1"
></mat-paginator>
