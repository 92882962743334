import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export interface GetClient {
  clientId: string;
}

export interface GetClientsWithIds {
  clientIds: string[];
}

export type GetClientsParams = {
  name: string;
};

export type GetClientsResponse = {
  clients: {
    name: string;
    id: string;
  }[];
};

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  async searchClients(params: GetClientsParams): Promise<GetClientsResponse> {
    const url = environment.baseUrl + '/api/client';
    const result = await axios.get<GetClientsResponse>(
      url,
      {
        params,
      },
    );

    return result.data;
  }
}
