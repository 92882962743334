import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CasesComponent } from "./dashboard/cases/cases.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuardService } from "./services/auth-guard/auth-guard.service";

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'attorney',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'cases', component: CasesComponent },
      { path: '**', redirectTo: 'cases' },
    ],
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
