import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login.component';
import { LoginTemplateModule } from 'src/app/shared/login-template/login-template.module';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    LoginTemplateModule,
  ],
})
export class LoginModule { }
