<payee-popup
  title="Fájl feltöltése"
  description="Ezeket csak ügyvédek fogják látni"
>
  <div class="content flex column">
    <payee-dropzone
      mainText="FÁJLOK FELTÖLTÉSE"
      accept="*"
      width="wide"
      (dropped)="onDrop($event)"
    ></payee-dropzone>

    <div class="files-list">
      <div
        *ngFor="let file of files"
        class="flex vcenter"
      >
        <span>{{file.name}}</span>
        <button
          payee-icon-button
          color="white"
          (click)="removeFile(file)"
        >
          <payee-icon [name]="environment.TRASH_ICON"></payee-icon>
        </button>
      </div>
    </div>
  </div>

  <span>Válaszd ki az ügyvédeket, akiket értesíteni szeretnél a feltöltésről.</span>
  <span color="alert-red">Legalább 1 kötelező.</span>

  <payee-form-field width="wide">
    <payee-autocomplete
      [options]="attorneys ?? []"
      [displayWith]="displayAttorney"
      [filterPredicate]="filter"
      [formControl]="attorneyControl"
    >
    </payee-autocomplete>
  </payee-form-field>

  <div
    *ngFor="let attorney of selectedAttorneys"
    class="flex vcenter"
  >
    <span>{{displayAttorney(attorney)}}</span>
    <button
      payee-icon-button
      color="white"
      (click)="unselectAttorney(attorney)"
    >
      <payee-icon [name]="environment.TRASH_ICON"></payee-icon>
    </button>
  </div>

  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button
      width="wide"
      (click)="onSubmit()"
      [disabled]="!valid"
    >Mentés</button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button
      payee-button
      mat-dialog-close
      [isBorderButton]="true"
      color="white"
      width="wide"
    >
      Mégse
    </button>
  </ng-template>
</payee-popup>
