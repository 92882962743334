<header class="flex">
  <form [formGroup]="selectorForm" class="filter-box grow1">
    <div class="flex filter-row">
      <payee-form-field>
        <div payee-label>
          <div class="flex vcenter filter-title">
            <span>Hitelező neve</span>
            <mat-progress-spinner
              diameter="20"
              mode="indeterminate"
              *ngIf="loading"
            ></mat-progress-spinner>
          </div>
        </div>
        <input
          payee-input
          formControlName="clientFilter"
          [matAutocomplete]="clientAuto"
        />
      </payee-form-field>
      <mat-autocomplete #clientAuto="matAutocomplete">
        <mat-option
          *ngFor="let client of clients"
          [value]="client.name"
          (click)="clientSelected(client)"
        >
          {{ client.name }}
        </mat-option>
      </mat-autocomplete>
      <button
        payee-icon-button
        color="white"
        (click)="clientSelected(); clientFilter.setValue('')"
      >
        <payee-icon name="trash-black"></payee-icon>
      </button>
    </div>
    <div class="flex filter-row">
      <payee-form-field>
        <div payee-label>
          <div class="flex vcenter filter-title">
            <span>Adós neve</span>
            <mat-progress-spinner
              diameter="20"
              mode="indeterminate"
              *ngIf="loading"
            ></mat-progress-spinner>
          </div>
        </div>
        <input
          payee-input
          formControlName="debtorFilter"
          [matAutocomplete]="debtorAuto"
        />
      </payee-form-field>
      <mat-autocomplete #debtorAuto="matAutocomplete">
        <mat-option
          *ngFor="let debtor of debtorNames"
          [value]="debtor"
          (click)="debtorSelected(debtor)"
        >
          {{ debtor }}
        </mat-option>
      </mat-autocomplete>
      <button
        payee-icon-button
        color="white"
        (click)="debtorSelected(); debtorFilter.setValue('')"
      >
        <payee-icon name="trash-black"></payee-icon>
      </button>
    </div>
    <div class="flex filter-row">
      <payee-form-field>
        <div payee-label>
          <div class="flex vcenter filter-title">
            <span>Ügyazonosító</span>
            <mat-progress-spinner
              diameter="20"
              mode="indeterminate"
              *ngIf="loading"
            ></mat-progress-spinner>
          </div>
        </div>
        <input payee-input formControlName="caseFilter" />
      </payee-form-field>
      <button payee-icon-button color="white" (click)="caseFilter.setValue('')">
        <payee-icon name="trash-black"></payee-icon>
      </button>
    </div>
  </form>

  <div class="profile-wrapper flex vcenter">
    <button payee-icon-button class="logout-button" (click)="logout()">
      <payee-icon name="logout-grey"></payee-icon>
    </button>
    <button payee-button color="grey-light" class="name-button">
      {{ userName }}
    </button>
  </div>
</header>

<app-table
  *ngIf="!casesLoading"
  [data]="tableData"
  [(pageSize)]="pageSize"
  [(pageIndex)]="pageIndex"
  [length]="length"
  (page)="pageChange($event)"
  (filterCase)="filterCase($event)"
></app-table>

<mat-progress-spinner
  class="cases-spinner"
  diameter="100"
  mode="indeterminate"
  *ngIf="casesLoading"
></mat-progress-spinner>
