import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: FormGroup;
  hidePassword = true;
  loading = false;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private titleService: Title,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.titleService.setTitle('Bejelentkezés - Payee');

    if (this.authService.isAuthenticated) {
      this.router.navigateByUrl(environment.afterLoginPath);
    }
  }

  get email(): AbstractControl { return this.loginForm.get('email') as AbstractControl; }
  get password(): AbstractControl { return this.loginForm.get('password') as AbstractControl; }

  readonly login = async () => {
    if (this.loginForm.invalid || this.loading) {
      return;
    }
    try {
      this.email.setErrors(null);
      this.password.setErrors(null);
      this.loading = true;

      await this.authService.login(this.email.value, this.password.value);
    } catch (e) {
      console.error('Unknown error', e);
      this.snackBar.open(
        'Valami hiba történt, próbáld újra késöbb!',
        'Ok',
        {
          duration: 10000,
        },
      );
    } finally {
      this.loading = false;
    }
  };
}
