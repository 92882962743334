<div class="logo-box">
  <img
    *ngIf="!closed"
    class="logo"
    src="assets/shared/global-images/payee-logos/v1/white-payee-white-dot.svg"
    width="100px"
    routerLink="dashboard"
  />
  <img
    *ngIf="closed"
    class="logo"
    src="assets/shared/global-images/payee-logos/v1/payee-webclip.svg"
    width="56.83px"
    routerLink="dashboard"
  />
</div>

<div class="routing-buttons">
  <button
    [routerLink]="['cases']"
    routerLinkActive="active"
    payee-button
    [isRoundButton]="false"
  >
    <div class="flex vcenter hcenter" style="gap: 16px">
      <img src="assets/shared/global-images/icons/list.svg" />
      <span *ngIf="!closed">Összes ügy</span>
    </div>
  </button>
</div>

<div class="footer">
  <!-- <ng-container *ngIf="!closed">
    <span color="grey-light">+36 20 537 4082</span>
    <span color="grey-light">hello@payee.tech</span>
    <span color="grey-light" class="version">Verziószám: <strong>v{{version}}</strong></span>
  </ng-container> -->

  <button
    payee-button
    class="toggle-button"
    [isRoundButton]="false"
    (click)="closed = !closed"
  >
    <img
      *ngIf="!closed"
      src="assets/shared/global-images/icons/arrow-double-left-grey.svg"
    />
    <img
      *ngIf="closed"
      src="assets/shared/global-images/icons/arrow-double-right-grey.svg"
    />
  </button>
</div>
